export const subjects = [
  {
    'S/N': 1,
    Subject: 'English Language',
    Code: 'ENG'
  },
  {
    'S/N': 2,
    Subject: 'Igbo Language',
    Code: 'IGB'
  },
  {
    'S/N': 3,
    Subject: 'Mathematics',
    Code: 'MAT'
  },
  {
    'S/N': 4,
    Subject: 'Civic Education',
    Code: 'CIV'
  },
  {
    'S/N': 5,
    Subject: 'Government',
    Code: 'GVT'
  },
  {
    'S/N': 6,
    Subject: 'Physics',
    Code: 'PHY'
  },
  {
    'S/N': 7,
    Subject: 'Chemistry',
    Code: 'CHM'
  },
  {
    'S/N': 8,
    Subject: 'Biology',
    Code: 'BIO'
  },
  {
    'S/N': 9,
    Subject: 'Agricultural Science',
    Code: 'AGR'
  },
  {
    'S/N': 10,
    Subject: 'History',
    Code: 'HST'
  },
  {
    'S/N': 11,
    Subject: 'Economics',
    Code: 'ECO'
  },
  {
    'S/N': 12,
    Subject: 'Financial Accounting',
    Code: 'ACC'
  },
  {
    'S/N': 13,
    Subject: 'Commerce',
    Code: 'COM'
  },
  {
    'S/N': 14,
    Subject: 'Computer Studies',
    Code: 'ICT'
  },
  {
    'S/N': 15,
    Subject: 'Literature in English',
    Code: 'LIT'
  },
  {
    'S/N': 17,
    Subject: 'Animal Husbandry',
    Code: 'AHD'
  },
  {
    'S/N': 18,
    Subject: 'Marketing',
    Code: 'MKT'
  },
  {
    'S/N': 19,
    Subject: 'Visual Arts',
    Code: 'VAT'
  },
  {
    'S/N': 20,
    Subject: 'Further Mathematics',
    Code: 'FMT'
  },
  {
    'S/N': 21,
    Subject: 'French Language',
    Code: 'FRN'
  },
  {
    'S/N': 22,
    Subject: 'Basic Science',
    Code: 'BSC'
  },
  {
    'S/N': 23,
    Subject: 'Pre-vocational Studies',
    Code: 'PVS'
  },
  {
    'S/N': 24,
    Subject: 'Christian Religious Studies',
    Code: 'CRS'
  },
  {
    'S/N': 25,
    Subject: 'National Values',
    Code: 'NVL'
  },
  {
    'S/N': 26,
    Subject: 'Business Studies',
    Code: 'BUS'
  },
  {
    'S/N': 27,
    Subject: 'Insurance',
    Code: 'INS'
  },
  {
    'S/N': 28,
    Subject: 'Office Practice',
    Code: 'OFP'
  },
  {
    'S/N': 29,
    Subject: 'Geography',
    Code: 'GEO'
  },
  {
    'S/N': 30,
    Subject: 'Bookkeeping',
    Code: 'BKP'
  },
  {
    'S/N': 31,
    Subject: 'Food and Nutrition',
    Code: 'FAN'
  },
  {
    'S/N': 32,
    Subject: 'Physical Health Education',
    Code: 'PHE'
  },
  {
    'S/N': 33,
    Subject: 'Building Engineering',
    Code: 'BDE'
  },
  {
    'S/N': 34,
    Subject: 'Building Construction',
    Code: 'BDC'
  },
  {
    'S/N': 35,
    Subject: 'Data Processing',
    Code: 'DPR'
  },
  {
    'S/N': 36,
    Subject: 'Cultural and creative Arts',
    Code: 'CCA'
  },
  {
    'S/N': 37,
    Subject: 'Home Economics',
    Code: 'HEC'
  },
  {
    'S/N': 38,
    Subject: 'Block building, bricklaying/concreting',
    Code: 'BBC'
  },
  {
    'S/N': 39,
    Subject: 'Technical Drawing',
    Code: 'TED'
  },
  {
    'S/N': 40,
    Subject: 'General Metals',
    Code: 'GEM'
  },
  {
    'S/N': 41,
    Subject: 'Electrical Installation/Maintenance',
    Code: 'EIM'
  },
  {
    'S/N': 42,
    Subject: 'Radio Electronic Works',
    Code: 'REW'
  },
  {
    'S/N': 43,
    Subject: 'Painting and Decoration',
    Code: 'PAD'
  },
  {
    'S/N': 44,
    Subject: 'Carpentry and Joinery',
    Code: 'CAJ'
  },
  {
    'S/N': 45,
    Subject: 'Motor vehicle maintenance',
    Code: 'MVM'
  },
  {
    'S/N': 46,
    Subject: 'Domestic Installation',
    Code: 'DMI'
  },
  {
    'S/N': 47,
    Subject: 'Pattern and Core making',
    Code: 'PCM'
  },
  {
    'S/N': 48,
    Subject: 'Mechanical Engineering',
    Code: 'MCE'
  },
  {
    'S/N': 49,
    Subject: 'Radio Communication',
    Code: 'RDC'
  },
  {
    'S/N': 50,
    Subject: 'General woodwork',
    Code: 'GEW'
  },
  {
    'S/N': 51,
    Subject: 'Fabrication and Welding',
    Code: 'FAW'
  },
  {
    'S/N': 52,
    Subject: 'Shorthand',
    Code: 'SHOR'
  },
  {
    'S/N': 53,
    Subject: 'Typewriting',
    Code: 'TYP'
  },
  {
    'S/N': 54,
    Subject: 'Refrigerator and air conditioning',
    Code: 'RAC'
  },
  {
    'S/N': 55,
    Subject: 'Garment making',
    Code: 'GMK'
  },
  {
    'S/N': 56,
    Subject: 'Social Studies',
    Code: 'SOS'
  },
  {
    'S/N': 57,
    Subject: 'Basic Technology',
    Code: 'BST'
  },
  {
    'S/N': 58,
    Subject: 'Verbal Reasoning',
    Code: 'VBR'
  },
  {
    'S/N': 59,
    Subject: 'Quantitative Reasoning',
    Code: 'QTR'
  },
  {
    'S/N': 60,
    Subject: 'Music',
    Code: 'MUS'
  },
  {
    'S/N': 61,
    Subject: 'Vocational aptitude',
    Code: 'VCA'
  },
];

export const DefaultSubject = {
  sr: null,
  exam: null,
  first_ca: null,
  grade: '',
  remark: '',
  second_ca: null,
  subject: '',
  subject_code: ''
};

