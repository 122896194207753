import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilService } from './util.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  adminUrl = environment.url + '/api/admins/';
  baordUrl = environment.url + '/api/boards/';
  constructor(
    private http: HttpClient,
    private util: UtilService,
  ) {
  }
  getRequest(getUrl) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    let baseUrl: string;
    this.util.getUserObject().role === 'board' ? baseUrl = this.baordUrl : baseUrl = this.adminUrl;
    return this.http.get(`${baseUrl + getUrl}`, { headers: header })
      .toPromise();
  }
  getRequestStats(getUrl) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    const baseUrl = environment.url;
    return this.http.get(`${baseUrl + '/api/' + getUrl}`, { headers: header })
      .toPromise();
  }
  postRequest(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    let baseUrl;
    this.util.getUserObject().role === 'board' ? baseUrl = this.baordUrl : baseUrl = this.adminUrl;
    return this.http.post(`${baseUrl + url}`, data, { headers: header })
      .toPromise();
  }
  postRequestBase(getUrl, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    const baseUrl = environment.url;
    return this.http.post(`${baseUrl + '/api/' + getUrl}`, data, { headers: header })
      .toPromise();
  }

  getRequestBase(getUrl) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    const baseUrl = environment.url;
    return this.http.get(`${baseUrl + '/api/' + getUrl}`, { headers: header })
      .toPromise();
  }


  patchRequest(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    let baseUrl;
    this.util.getUserObject().role === 'board' ? baseUrl = this.baordUrl : baseUrl = this.adminUrl;
    return this.http.patch(`${baseUrl + url}`, data, { headers: header })
      .toPromise();
  }

  patchRequestBase(getUrl, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    const baseUrl = environment.url;
    return this.http.patch(`${baseUrl + '/api/' + getUrl}`, data, { headers: header })
      .toPromise();
  }

  postRequestBoard(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    return this.http.post(`${this.baordUrl + url}`, data, { headers: header })
      .toPromise();
  }
  postRequestAdmin(url, data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.util.getToken()}`
    });
    return this.http.post(`${this.adminUrl + url}`, data, { headers: header })
      .toPromise();
  }
  loginBoard(data) {
    return this.postRequestBoard('login', data);
  }
  loginAdmin(data) {
    return this.postRequestAdmin('login', data);
  }
  registerBoard(data) {
    return this.postRequestAdmin('board_register', data);
  }
  createCategory(data) {
    return this.postRequestAdmin('new_category', data);
  }
  createType(data) {
    return this.postRequestAdmin('new_type', data);
  }
  addStudent(data) {
    return this.postRequest('register/student', data);
  }
  createAdmin(data) {
    return this.postRequestAdmin('register', data);
  }
  updateAccountType(data) {
    return this.patchRequest('update_user_type', data);
  }

  updateProfile(data) {
    return this.patchRequest('profile/edit', data);
  }
  getSchools(skip, lga, type, cat, zone, area, str, lim) {
    return this.getRequest(`schools?skip=${skip}&lga=${lga}&type=${type}&category=${cat}&zone=${zone}&area=${area}&name=${str}&limit=${lim}`);
  }
  getBoard() {
    return this.getRequest(`boards?skip=0&designation`);
  }
  getAdmin() {
    return this.getRequest(`all-admins?skip=0`);
  }
  generatePINS(data) {
    return this.postRequestBase('pins/generate-pins', data);
  }
  getPINS(query) {
    return this.getRequestStats(`pins/pins?skip=${query.skip}&limit=${query.limit}&printed=${query.printed}&valid=${query.validity}&pin_type=${query.type}&pin=${query.searchPINStr}`);
  }
  printPIN(pinArray) {
    return this.postRequestBase(`pins/mark_as_printed`, pinArray);
  }
  getTypes() {
    return this.getRequest(`all-types?skip=0`);
  }
  getCategories() {
    return this.getRequest(`categories?skip=0`);
  }
  getExams() {
    return this.getRequest(`exams`);
  }
  createExams(data: any) {
    return this.postRequest(`create_exam`, data);
  }
  getExamStatistics() {
    return this.getRequest(`exam-stats`);
  }
  getConfig() {
    return this.getRequestBase(`configs/get-config`);
  }

  createConfig(data) {
    return this.patchRequestBase(`configs/update_config`, data);
  }

  transferStudents(data) {
    return this.postRequest(`bulk-transfer`, data);
  }
  deleteStudents(data) {
    return this.postRequest('delete_students', data);
  }
  graduateStudents(data) {
    return this.postRequestBase('results/graduate-students', data);
  }
  promoteStudents(data) {
    return this.postRequestBase('results/admin-promote-student', data);
  }
  editStudentInfo(data, id: string) {
    return this.patchRequest(`student/profile/edit/${id}`, data);
  }
  updatePassword(data) {
    return this.postRequest('school_password_reset', data);
  }
  updateAdminPassword(data) {
    return this.patchRequest('update_password', data);
  }
  updateTypeAndCat(data) {
    return this.patchRequest('update_school_type_category', data);
  }
  logoutAll() {
    return this.postRequest('logout/all', {});
  }
  getStudents(skip, name, klas, cat, id, lim) {
    const nameStr = this.util.trimWhiteSpace(name);
    return this.getRequest(`students?skip=${skip}&name=${nameStr}&class=${klas}&school_category=${cat}&school_id=${id}&limit=${lim}`);
  }

  waecList(waecClass: number, schoolId: string) {
    return this.getRequest(`students?class=${waecClass}&school_id=${schoolId}`);
  }


  getStudentsNoLimit() {
    return this.getRequest(`students`);
  }
  getInvoice(query: any) {
    // tslint:disable-next-line: max-line-length
    // &school_id=${query.school_id}
    return this.getRequest(`invoices?skip=${query.skip}&limit=${query.limit}&school_id=${query.school_id}&category=${query.category}&type=${query.type}&examination=${query.examination}&lga=${query.lga}&zone=${query.zone}&area=${query.area}&session=${query.session}&reference=${query.reference}&verified=${query.status}`);
  }
  getArchived(id: string) {
    return this.getRequest(`get_deleted_student/${id}?skip=0&limit=`);
  }
  restoreStudents(data: any) {
    return this.postRequest(`undelete_students`, data);
  }
  // skpi,lga,limit,sortBy
  getStatistics(skip, str, lga, cat, lvl, grd, lim) {
    return this.getRequest(`statistics?skip=${skip}&name=${str}&lga=${lga}&category=${cat}&level=${lvl}&grade=${grd}&limit=${lim}`);
  }

  getGeneralStats(query) {
    return this.getRequestStats(`statistics/general_statistics_one?skip=${query.skip}&limit=${query.limit}&type=${query.type}&category=${query.category}&lga=${query.lga}&zone=${query.zone}&area=${query.area}`);
  }

  getGeneralStatsDetails(query) {
    return this.getRequestStats(`statistics/general_statistics_two?skip=${query.skip}&limit=${query.limit}&type=${query.type}&category=${query.category}&lga=${query.lga}&zone=${query.zone}&area=${query.area}`);
  }

  invoiceStatistics() {
    return this.getRequestStats(`statistics/invoices`);
  }
  paidInvoiceStatistics(query) {
    return this.getRequestStats(`statistics/school_invoice_statistics?skip=0&limit=10000000000&session=${query.session}&exam_id=${query.examination}&category=${query.category}&type=${query.type}&lga=${query.lga}&zone=${query.zone}&area=${query.area}`);
  }

  updateStudent(id, data) {
    return this.patchRequest(`student/profile/edit/${id}`, data);
  }
  getSubjectWithStats() {
    return this.getRequest(`student/subject/statistics`);
  }
  getSubjects() {
    return this.getRequest(`all_subjects?skip=0&limit=`);
  }
  promotionFix(data) {
    return this.postRequest(`classes_promotion_fix`, data);
  }
  approval(data) {
    return this.postRequest(`approve_schools`, data);
  }
  getSchoolSubjectStats(id) {
    return this.getRequest(`get_subject_stats?school_id=${id}`);
  }
  allResult(query) {
    return this.getRequest
      // tslint:disable-next-line: max-line-length
      (`results?skip=${query.skip}&limit=${query.limit}&grade=${query.grade}&term=${query.term}&class=${query.klas}&session=${query.session}&school_id=${query.school_id}`);
  }

  sendSMS(data) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-access-token': 'healthradar 1200980'
    });
    const url = `https://isusubackend.herokuapp.com/api/client/sms`;
    return this.http.post(`${url}`, data, { headers: header })
      .toPromise();
  }
  deactivateSchool(data) {
    return this.postRequest(`disable_school`, data);
  }
  verifyInvoice(data) {
    return this.postRequest(`approve-invoice`, data);
  }
}
