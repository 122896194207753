import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { observable, BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { ExportToCsv } from 'export-to-csv';
import { MatDialogConfig, MatSnackBar } from '@angular/material';
import { terms } from '../models/terms';
import { DefaultSubject, subjects } from '../models/subjects';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { adult, adultArray, primary, PrimaryArray, secondary, SecondaryArray } from '../models/school-classes';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  encryptSecretKey = 'diego';
  msg: string;
  logostring: string;
  subj: any;
  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private snackBar: MatSnackBar
  ) {
  }

  private msgSubject = new BehaviorSubject<any>('');
  message = this.msgSubject.asObservable();

  private logoSubject = new BehaviorSubject<any>('');
  logo = this.logoSubject.asObservable();

  private subjSubject = new BehaviorSubject<any>('');
  subject = this.subjSubject.asObservable();

  private PINSubject = new BehaviorSubject<any>('');
  PIN = this.PINSubject.asObservable();

  private CategorySubject = new BehaviorSubject<any>('');
  category = this.CategorySubject.asObservable();

  private TypeSubject = new BehaviorSubject<any>('');
  type = this.TypeSubject.asObservable();

  setMessage(data) {
    this.msgSubject.next(data);
  }

  setCategory(data) {
    this.CategorySubject.next(data);
  }

  setType(data) {
    this.TypeSubject.next(data);
  }

  setPIN(data) {
    this.PINSubject.next(data);
  }

  logoInfo(data) {
    this.logoSubject.next(data);
  }

  subjectInfo(data) {
    this.subjSubject.next(data);
  }

  isAthourized(allowedUsertypes: string[], allowedaccounttypes: string[]): any {
    // check if the list of allowedusertpes for aroute is empty, if empty, authorize the user to access the page
    if (allowedUsertypes == null || allowedUsertypes.length === 0) {
      return true;
    }
    const authUsertype = this.getUserObject() ? this.getUserObject().role : null;
    const accountType = this.getUserObject() ? this.getUserObject().user_type : null;

    const allowAccountType = allowedUsertypes == null || allowedUsertypes.length === 0 ? true : false;
    return allowedUsertypes.includes(authUsertype) &&
      allowAccountType ? true : allowedaccounttypes.includes(accountType);
  }

  setUserObject(userObject) {
    localStorage.setItem('ki', CryptoJS.AES.encrypt(JSON.stringify(userObject), this.encryptSecretKey).toString());
  }

  getUserObject() {
    const user = localStorage.getItem('ki');
    if (user) {
      const bytes = CryptoJS.AES.decrypt(user, this.encryptSecretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }

  setToken(token) {
    localStorage.setItem('xxx', CryptoJS.AES.encrypt(JSON.stringify(token), this.encryptSecretKey).toString());
  }

  getToken() {
    const token = localStorage.getItem('xxx');
    if (token) {
      const bytes = CryptoJS.AES.decrypt(token, this.encryptSecretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
  removeUser() {
    localStorage.removeItem('ki');
    localStorage.removeItem('xxx');
  }

  isLoggedIn(): boolean {
    const token = this.getToken();
    if ((localStorage.getItem('ki')) &&
      token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    return false;
  }
  // SNACKBAR METHODS
  succesSnackbar(msg): void {
    this.snackbarConfig(
      'Success', msg, 'success-snackbar'
    );
  }

  altSnackbar(msg): void {
    this.snackbarConfig(
      'Success', msg, 'dark-snackbar'
    );
  }

  errorSnackbar(msg): void {
    this.snackbarConfig(
      'Error', msg, 'error-snackbar'
    );
  }

  warningSnackbar(msg): void {
    this.snackbarConfig(
      'Warning', msg, 'warning-snackbar'
    );
  }
  blacksnackbar(msg: string): void {
    this.snackbarConfig(
      'Input error', msg, 'black-snackbar'
    );
  }

  snackbarConfig(title, msg, theme): void {
    this.snackBar.open(title, msg, {
      duration: 7000,
      verticalPosition: 'top',
      // horizontalPosition: 'right',
      panelClass: [theme],
    });
  }

  logout() {
    this.removeUser();
    this.router.navigateByUrl('/');
  }
  parseSubjectClass(val) {
    if (val < 7) {
      return `Primary ${val}`;
    }
    if (val > 6 && val < 10) {
      return `JS ${val - 6}`;
    }
    if (val > 9) {
      return `SS ${val - 9}`;
    }
  }

  // MATERIAL DIALOG HANDLES
  dialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;
    return dialogConfig;
  }

  // CSV EXPORT
  export(data, headers, title) {
    const options = {
      fieldSeparator: ',',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: `${title}-${Math.floor(Math.random() * 1000) + 1} `,
      useTextFile: false,
      useBom: true,
      headers: []
    };
    options.headers = headers;
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  // Format invoice reference to contain dash after 3 digits
  formatRef(ref: string): string {
    return ref.match(/\d{3}(?=\d{2,3})|\d+/g).join('-');
  }

  // Add comma after every three digits in amount
  formatAmount(amount: string | number): string {
    let amountStr = amount.toString();
    return amountStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  // Remove and replace letter
  removeReplace(data: string | Array<any>, rm: string, rp: string): string {
    let formatted: string;
    formatted = data.toString().split(rm).join(rp);
    return formatted;
  }

  // Revert invoice reference formating
  revertRef(ref: string): string {
    return ref.match(/\d{3}(?=\d{2,3})|\d+/g).join('');
  }

  // Capitalize first letter
  capitalize(str: string): string {
    if (str) {
      const newStr = str.toLowerCase(); // to convert bring every character to lowercase first
      return newStr.charAt(0).toUpperCase() + newStr.slice(1);
    }
    return '';
  }

  sortAlphabetically(data: Array<any>, field: string) {
    data.sort((a, b) => a[field].localeCompare(b[field]))
    return data;
  }

  sessionDropdown() {
    const options = [];
    for (let index = 0; index < 3; index++) {
      const current = new Date().getFullYear() + 1 - index;
      const prev = current - 1;
      options.push(`${prev}/${current}`);
    }
    return options;
  }
  sortData(data) {
    const sorted = data.sort((a, b) => {
      return a.class - b.class;
    });
    return sorted;
  }

  // Scroll top
  scrollTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  // Process bulk students IDs
  processBulk(data: Array<any>): Array<any> {
    const IDArray = [];
    data.forEach(item => {
      IDArray.push(item._id);
    });
    return IDArray;
  }

  // determnine time of the day
  dayTime(): string {
    const hr = new Date().getHours();
    if (hr < 12) {
      return 'Good Morning';
    }
    if (hr >= 12 && hr < 16) {
      return 'Good Afternoon';
    }
    if (hr >= 16) {
      return 'Good Evening';
    }
  }

  // Transofrm Result to contain subject name and grade
  getSubjectsList(results: Array<any>): Array<any> {
    const subArray = [];
    results.forEach(item => {
      item.subjects.forEach(subject => {
        if (!subArray.includes(subject.subject_code)) {
          subArray.push(subject.subject_code);
        }
      });
    });
    return subArray;
  }

  transformResult(results: Array<any>): any {
    // Remove duplicates
    results.map(item => {
      item.subjects = _.uniqBy(item.subjects, obj => obj.subject_code);
    });
    const subjectList = this.getSubjectsList(results);
    results.forEach(item => {
      item.total_marks = item.subjects.length * 100;
      item.marks_scored = item.total_scored;
      item.marks_scored = item.subjects.reduce(
        (acc, curr) => acc + (curr.score + curr.second_ca + curr.third_ca + curr.third_ca),
        0,
      );
      // item.class_average = Math.round(item.class_average);
      // item.percentage = Math.round(item.average);
      // item.percentage = Math.round((item.marks_scored / item.total_marks) * 100);
      const newsubjects = [];
      for (let index = 0; index < subjectList.length; index++) {
        DefaultSubject.sr = index;
        newsubjects.push(DefaultSubject);
      }

      item.subjects.map(element => {
        const exam = element.score ? element.score : 0;
        const ca1 = element.first_ca ? element.first_ca : 0;
        const ca2 = element.second_ca ? element.second_ca : 0;
        const score = exam + ca1 + ca2;
        element.total = score;
        element.grade = this.determineGrade(score).grade;
        element.remark = this.determineGrade(score).remark;
        // rearranging the results in the subject list order
        const newIndx = subjectList.indexOf(element.subject_code);
        newsubjects[newIndx] = element;
      });
      item.subjects = newsubjects;
      // process pass/fail status if not ptocessed from backend (to be done from backend)

    });
    return {
      subject_list: subjectList,
      result_list: results,
    };
  }

  PromotionCheckOne(others, eng, maths): boolean {
    if (others >= 3 && (eng >= 40 && maths >= 40)) {
      return true;
    }
    return false;
  }
  PromotionCheckTwo(others, eng, maths): boolean {
    if (others >= 6 && (eng >= 40 || maths >= 40)) {
      return true;
    }
    return false;
  }
  // To process grades for different scores
  determineGrade(scoreStr): any {
    const grading = {
      grade: '',
      remark: ''
    };
    const score = Number(scoreStr);
    if (score < 40) {
      grading.grade = 'F9';
      grading.remark = 'Fail';
    }

    if (score >= 40 && score < 45) {
      grading.grade = 'E8';
      grading.remark = 'Pass';
    }
    if (score >= 45 && score < 50) {
      grading.grade = 'D7';
      grading.remark = 'Pass';
    }
    if (score >= 50 && score < 60) {
      grading.grade = 'C6';
      grading.remark = 'Credit';
    }
    if (score >= 60 && score < 65) {
      grading.grade = 'C5';
      grading.remark = 'Credit';
    }
    if (score >= 65 && score < 70) {
      grading.grade = 'C4';
      grading.remark = 'Credit';
    }
    if (score >= 70 && score < 75) {
      grading.grade = 'B3';
      grading.remark = 'Good';
    }
    if (score >= 75 && score < 80) {
      grading.grade = 'B2';
      grading.remark = 'Very Good';
    }
    if (score >= 80) {
      grading.grade = 'A1';
      grading.remark = 'Excellent';
    }
    return grading;
  }
  // To get term string instead of number
  determineTerm(term: number): string {
    const termStr = terms.filter(el => {
      return el.term === term;
    });
    return termStr[0].title;
  }


  // Format pin to contain space after 3 digits
  formatPIN(ref): string {
    return ref.match(/\d{4}(?=\d{2,3})|\d+/g).join(' ');
  }
  // Revert PIN formating
  revertPIN(ref): string {
    return ref.match(/\d{3}(?=\d{2,3})|\d+/g).join('');
  }
  // Reconvert pin back to invoice reference to remove space after 4 digits
  convertPIN(ref): string {
    return ref.match(/\d{4}(?=\d{2,3})|\d+/g).join('');
  }

  sortArr(data, field): Array<any> {
    return _.orderBy(data, [field], ['asc']);
  }

  // to replace multiple white space with one space
  public trimWhiteSpace(text: string): string {
    const formattedText = text.trim().replace(/\s+/g, " ");
    return formattedText;
  }


  // GEt school classes depending on sschool type
  schoolClasses(type: string): Array<any> {
    if (type === '50') {
      return primary;
    } else if (type === '51') {
      return secondary;
    } else {
      return adult;
    }
  }

}

