import { Component, OnInit } from '@angular/core';
import { UtilService } from '../service/util.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CrudService } from '../service/crud.service';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loader = false;
  msg = '';
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private crud: CrudService,
    private util: UtilService,
    private alert: AlertService
  ) {
    this.loginForm = this.formbuilder.group({
      password: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    localStorage.clear();
  }
  async login() {
    try {
      this.loader = true;
      const data: any = await this.crud.loginBoard(this.loginForm.value);
      this.util.succesSnackbar('sign-In successful');
      data.board.role = 'board';
      this.util.setToken(data.token);
      this.util.setUserObject(data.board);
      this.router.navigate(['/dashboard']);
    } catch (err) {
      if (err.status === 401 || err.status === 400) {
        await this.loginAdmin();
      }
      if (err.status === 0) {
        this.alert.danger('Network error');
      }
      this.loader = false;
    }
  }
  async loginAdmin() {
    try {
      this.loader = true;
      const data: any = await this.crud.loginAdmin(this.loginForm.value);
      this.util.succesSnackbar('sign-In successful');
      data.admin.role = 'admin';
      this.util.setToken(data.token);
      this.util.setUserObject(data.admin);
      this.router.navigate(['/dashboard']);
    } catch (err) {
      this.util.errorSnackbar(err.error);
      if (err.status === 0) {
        this.util.errorSnackbar('Network error');
      }
      // this.noUserFound(err);
      this.loader = false;
    }
  }
  // noUserFound(err) {
  //   if (err.status === 401) {
  //     this.alert.danger(err.error);
  //   }
  // }
}
