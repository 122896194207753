import { AuthGuardGuard } from './Guard/auth.guard';
import { NavigationComponent } from './user/navigation/navigation.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { UnathourizedComponent } from './unathourized/unathourized.component';


const routes: Routes = [
  { path: '', data: { title: 'Examportal | Login' }, component: LoginComponent },
  { path: '404', data: { title: 'Examportal | 404' }, component: UnathourizedComponent },
  {
    path: 'dashboard',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
