import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { AlertModule } from 'ngx-alerts';
// import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpinterceptorService } from './interceptor/httpinterceptor.service';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material/material.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { CrudService } from './service/crud.service';
import { UtilService } from './service/util.service';
import { UnathourizedComponent } from './unathourized/unathourized.component';

export function tokenGetter() {
  return localStorage.getItem('xxx');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotificationsComponent,
    UnathourizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    AlertModule,
    // NgxUiLoaderModule,
    ReactiveFormsModule,
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, position: 'right' }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    BrowserAnimationsModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    HttpClientModule,
    CrudService,
    UtilService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
