export const terms = [
    {
        title: 'First Term',
        term: 1,
    },
    {
        title: 'Second Term',
        term: 2,
    },
    {
        title: 'Third Term',
        term: 3,
    }
];
