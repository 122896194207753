export const primary = [
    {
        code: 100,
        title: 'Day Care',
        abbr: 'DC',
    },
    {
        code: 200,
        title: 'Creche',
        abbr: 'CRCH',
    },
    {
        code: 300,
        title: 'Pre Nursery',
        abbr: 'PRN',
    },
    {
        code: 400,
        title: 'Nursery 1',
        abbr: 'NS1',
    },
    {
        code: 500,
        title: 'Nursery 2',
        abbr: 'NS2',
    },
    {
        code: 600,
        title: 'Nursery 3',
        abbr: 'NS3',
    },
    {
        code: 1,
        title: 'Primary 1',
        abbr: 'PR1',
    },
    {
        code: 2,
        title: 'Primary 2',
        fee: 18000,
        abbr: 'PR2',
    },
    {
        code: 3,
        title: 'Primary 3',
        abbr: 'PR3',
    },
    {
        code: 4,
        title: 'Primary 4',
        abbr: 'PR4',
    },
    {
        code: 5,
        title: 'Primary 5',
        abbr: 'PR5',
    },
    {
        code: 6,
        title: 'Primary 6',
        abbr: 'PR6',
    },
];

export const adult = [
    {
        code: 1000,
        title: 'Adult',
        abbr: 'Adult',
    }
];

export const secondary = [
    {
        code: 7,
        title: 'JS 1',
        abbr: 'JS1',
    },
    {
        code: 8,
        title: 'JS 2',
        abbr: 'JS2',
    },
    {
        code: 9,
        title: 'JS 3',
        abbr: 'JS3',
    },
    {
        code: 10,
        title: 'SS 1',
        abbr: 'SS1',
    },
    {
        code: 11,
        title: 'SS2 ',
        abbr: 'SS2',
    },
    {
        code: 12,
        title: 'SS 3',
        abbr: 'SS3',
    }
];

export const OptionalClass = [
    {
        code: 11,
        title: 'SS 2 '
    },
    {
        code: 12,
        title: 'SS 3'
    },
];

export const SeniorClass = [
    {
        code: 10,
        title: 'SS 1 '
    },
    {
        code: 11,
        title: 'SS 2'
    },
    {
        code: 12,
        title: 'SS 3'
    },
];

export const PrimaryArray = [
    100, 200, 300, 400, 500, 600, 1, 2, 3, 4, 5, 6
];

export const adultArray = [
    1000
];

export const SecondaryArray = [
    7, 8, 9, 10, 11, 12
];
